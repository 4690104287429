import request from "@/utils/request";

export function getCategoryList() {
  return request({
    url: "/getCategoryList",
    method: "get",
  });
}

export async function getCategory(data) {
  const name = data.name;
  const curPage = data.curPage;
  const pageSize = data.pageSize;
  return request({
    url: "/getCategory/" + name,
    method: "get",
    params: {
      curPage,
      pageSize,
    },
  });
}
