<template>
  <div class="category-container">
    <Header />
    <el-main>
      <el-row justify="center" align="middle">
        <el-col :xs="22" :sm="20" :md="18" :lg="16" :xl="12">
          <el-breadcrumb separator="/" class="animated fadeInDown">
            <span style="float: left">当前位置：</span>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>分类</el-breadcrumb-item>
          </el-breadcrumb>
          <el-row :gutter="20" style="z-index: 99">
            <el-col
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
              v-for="(item, index) in categoryList"
              :key="index"
            >
              <a
                href="javascript: void(0)"
                @click="this.$router.push('/category/' + item.name)"
              >
                <div class="category">
                  <div class="cover">
                    <el-image fit="cover" :src="item.coverUrl">
                      <template #placeholder>
                        <img
                          style="height: 100%"
                          src="@/assets/image/loading.gif"
                        />
                      </template>
                      <template #error>
                        <div class="image-slot">
                          <i class="fa fa-image"></i>
                        </div>
                      </template>
                    </el-image>
                    <div class="name">{{ item.name }}</div>
                  </div>
                  <div class="info">
                    <div class="article-num">
                      <i class="iconfont icon-wenzhang"></i>
                      {{ item.count }}篇文章
                    </div>
                    <a href="javascript: void(0)"> 查 看 </a>
                  </div>
                </div>
              </a>
            </el-col>
          </el-row>
          <el-empty v-show="empty" description="没有分类信息" />
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import Header from "@/components/common/header";
import { getCategoryList } from "@/api/category";
export default {
  name: "index",
  components: { Header },
  data() {
    return {
      categoryList: [],
      empty: true,
    };
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    getCategoryList() {
      getCategoryList().then((res) => {
        if (res.code == 200) {
          this.categoryList = res.data;
          this.empty = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.category-container {
  width: 100%;
  height: 100%;
  color: var(--text-color);
}
.el-image {
  width: 100%;
  height: 100%;
}
.category {
  position: relative;
  border-radius: 0.625rem;
  margin: 2rem 0;
  font-family: "BiaoSong", sans-serif;
  cursor: pointer;
}
.category:hover {
  box-shadow: 0 1px 20px -4px var(--shadow-hover-color);
}
.category .cover {
  position: relative;
  width: 100%;
  height: 225px;
  border-radius: 0.625rem;
  font-size: 1em;
  overflow: hidden;
  transform: rotateY(0);
}
.category .cover img {
  width: 100%;
  height: 225px;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.5s ease 0s;
}
.category:hover .cover img {
  transform: scale(1.05);
}
.category .cover .name {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  text-align: center;
  font-size: 24px;
}
.category .cover .name:hover {
  color: var(--primary-color);
}
.category .info {
  opacity: 0;
}
.category:hover .info {
  opacity: 1;
}
.category .info .article-num {
  position: absolute;
  bottom: 0px;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  letter-spacing: 2px;
  border-bottom-left-radius: 0.625rem;
  transform: translateY(10px);
  transition: all 0.8s;
}
.category:hover .info .article-num {
  transform: translateY(0px);
}
.category .info a {
  position: absolute;
  bottom: -3px;
  right: -4px;
  transform-style: preserve-3d;
  transform: translateZ(3rem);
  padding: 0.5rem 1rem;
  border-radius: 1rem 0;
  background: var(--primary-color);
  color: #fff;
  text-decoration: none;
  opacity: 0;
  transition: all 0.5s;
}
.category .info a:hover {
  box-shadow: 0 1px 20px -4px var(--primary-color);
}
.category:hover .info a {
  opacity: 1;
}
</style>
